import _get from 'lodash/get'

export function data() {
  const authUserType = _get(this, ['$store', 'getters', 'user/authUserType'], null)

  return {
    isDebug: true,
    authUserType,
    loading: true,
    amenityID: 0
  }
}
