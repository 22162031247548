<template>
  <PageContent
    :title="$t('conversations.title')"
    :style="{ paddingBottom: '300px' }"
    conversations-main-page
  >
    <ListAmenityConversations :amenityID="parseInt(amenityID)" />
  </PageContent>
</template>

<script>
import ListAmenityConversations from '@/pages/Shared/communications/Global/List'
import PageContent from '@/containers/PageContent'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'

export default {
  components: {
    PageContent,
    ListAmenityConversations
  },
  data,
  methods,
  mounted,
  i18n: {
    messages: {
      en: { conversations: { title: 'Conversations' } }
    }
  }
}
</script>
